body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.topnav {
  overflow: hidden;
  background-color: #333;
}

.logo-img {
  width: 100px; /* Set desired width */
  height: 100px; /* Adjust height proportionally */
}

.highlight {
  color: crimson; /* Set your desired color */
  transition: all 0.3s ease;
}

.no-underline {
  text-decoration: none;
  color: inherit; /* Ensure the text color of the link is inherited */
  font-size: 13px;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ac7a42;
  color: black;
}

.topnav a.active {
  background-color: #c5c94f;
  color: white;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}
