.footer {
  background-color: #bdbd3b;
  padding: 20px 0;
  text-align: center;
  color: #080808;
}

.footer .container {
  max-width: 600px; /* Adjust the max-width as per your requirement */
  margin: auto;
}

.footer h3 {
  margin-bottom: 10px;
  font-size: 20px;
}

.footer .highligh {
  color: #0d608a;
}

.footer a {
  margin: 0 10px;
  color: #0d608a;
  text-decoration: none;
  font-size: 18px;
}

.footer a:hover {
  color: #007bff;
}

.footer .copyright,
.footer .credits {
  margin-top: 10px;
  font-size: 12px; /* Adjust font size for copyright and credits */
}

.footer .credits a {
  color: #0e0f10;
  text-decoration: underline;
  font-weight: bold;
}

.footer .credits a:hover {
  text-decoration: underline;
}
