.CardItem {
  width: auto;
  height: auto;
  /* Define a container size that all cards should follow */
}

.CardItem-img {
  width: 100%; /* Make the image fill the width of the container */
  height: 150px; /* Set a consistent height for all images (adjust as needed) */
  object-fit: contain; /* Ensures images fill the container without stretching */
  display: block; /* Removes any extra space below the image */
  margin: 0 auto; /* Center the image inside the container */
}

/* Tablet styles (landscape and portrait) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .CardItem-img {
    height: 250px; /* Adjust height for tablet (or any desired value) */
    /* object-fit: cover; */
    object-fit: contain;
  }
}

/* Mobile styles (portrait) */
@media only screen and (max-width: 767px) {
  .CardItem-img {
    height: 150px; /* Adjust height for mobile devices */
    /* object-fit: cover; */
    object-fit: contain;
  }
}
